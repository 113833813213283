import { ComponentStyleConfig, theme } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    color: 'white',
    fontSize: '1rem',
    fontWeight: 400,
  },
  variants: {
    solid: props => ({
      ...theme.components.Button.variants?.solid(props),
      fontSize: 'inherit',
      color: 'white',
    }),
    link: props => ({
      ...theme.components.Button.variants?.link(props),
      fontSize: 'inherit',
      color: 'blue.800',
    }),
    store: props => ({
      ...theme.components.Button.variants?.solid(props),
      bgGradient: 'linear(to-r, store.secondary, store.primary)',
      shadow: 'lg',
      color: 'white',
      _active: {
        bgGradient: 'linear(to-r, store.secondary, store.primary)',
      },
      _hover: {
        bgGradient: 'linear(to-l, store.secondary, store.primary)',
        _disabled: {
          bgGradient: 'linear(to-r, store.secondary, store.primary)',
        },
      },
    }),
    benefit: props => ({
      ...theme.components.Button.variants?.solid(props),
      bgGradient: 'linear(to-r, benefit.secondary, benefit.primary)',
      shadow: 'lg',
      color: 'white',
      _active: {
        bgGradient: 'linear(to-r, benefit.secondary, benefit.primary)',
      },
      _hover: {
        bgGradient: 'linear(to-l, benefit.secondary, benefit.primary)',
        _disabled: {
          bgGradient: 'linear(to-r, benefit.secondary, benefit.primary)',
        },
      },
    }),
    network: props => ({
      ...theme.components.Button.variants?.solid(props),
      bgGradient: 'linear(to-r, network.secondary, network.primary)',
      shadow: 'lg',
      color: 'white',
      _active: {
        bgGradient: 'linear(to-r, network.secondary, network.primary)',
      },
      _hover: {
        bgGradient: 'linear(to-l, network.secondary, network.primary)',
        _disabled: {
          bgGradient: 'linear(to-r, network.secondary, network.primary)',
        },
      },
    }),
    zmyle: props => ({
      ...theme.components.Button.variants?.solid(props),
      bgGradient: 'linear(to-r, zmyle.primary, zmyle.secondary)',
      shadow: 'lg',
      color: 'white',
      _active: {
        bgGradient: 'linear(to-r, zmyle.primary, zmyle.secondary)',
      },
      _hover: {
        bgGradient: 'linear(to-l, zmyle.primary, zmyle.secondary)',
        _disabled: {
          bgGradient: 'linear(to-r, zmyle.primary, zmyle.secondary)',
        },
      },
    }),
  },
};
