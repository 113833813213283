import { isServer } from 'utils/helpers';

const getEnvValue = <T extends string | number | boolean>(
  env: string | boolean | undefined,
  defaultValue: T,
): T => {
  try {
    if (env === undefined) return defaultValue;

    if (typeof defaultValue === typeof env) return env as T;

    if (!Number.isNaN(Number(env))) return Number(env) as T;

    return env as T;
  } catch {
    return defaultValue;
  }
};

export interface IClientConfig {
  appSignalKey: string;
  isProduction: boolean;
  maptilerKey: string;
  maptilerUrl: string;
  storeIgnoreNetworkConnectionIds: string[];
  googleAnalyticsId: string;
  facebookPixelId: string;
  linkedinPartnerId: string;
  zmyleWidgetUrl: string;
}

export class ClientConfig {
  // eslint-disable-next-line no-use-before-define
  private static instance: ClientConfig;

  private config: IClientConfig;

  private constructor(config?: Partial<IClientConfig>) {
    const isProduction = process.env.NODE_ENV === 'production';

    const maptilerKey = getEnvValue(
      process.env.NEXT_PUBLIC_APP_MAPTILER_KEY,
      '',
    );

    this.config = {
      appSignalKey: getEnvValue(process.env.NEXT_PUBLIC_APP_SIGNAL_KEY, ''),
      isProduction,
      maptilerKey,
      maptilerUrl: `https://api.maptiler.com/maps/db5d3dde-1d27-46eb-a792-db9aa4f4029e/style.json?key=${maptilerKey}`,
      googleAnalyticsId: getEnvValue(
        process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
        '',
      ),
      facebookPixelId: getEnvValue(
        process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
        '',
      ),
      linkedinPartnerId: getEnvValue(
        process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID,
        '',
      ),
      zmyleWidgetUrl: isProduction
        ? 'https://edge.zmyle.de/static/zmyle-cookie-widget/zmyle-cookie-widget.min.js'
        : 'https://edge.zmyle.de/static/zmyle-cookie-widget/zmyle-cookie-widget-dev.min.js',
      storeIgnoreNetworkConnectionIds: getEnvValue(
        process.env.NEXT_PUBLIC_APP_STORES_IGNORE_NETWORK_CONNECTIONS,
        '',
      )
        .replace(/\s/g, '')
        .split(','),
      ...config,
    };

    if (!isServer()) {
      window.analytics = {};

      if (this.config.googleAnalyticsId) {
        window.analytics.googleAnalyticsId = this.config.googleAnalyticsId;
      }

      if (this.config.facebookPixelId) {
        window.analytics.facebookPixelId = this.config.facebookPixelId;
      }

      if (this.config.linkedinPartnerId) {
        window.analytics.linkedinPartnerId = this.config.linkedinPartnerId;
      }
    }
  }

  public static getInstance() {
    if (!ClientConfig.instance) ClientConfig.instance = new ClientConfig();

    return ClientConfig.instance;
  }

  public static set(config: Partial<IClientConfig>) {
    ClientConfig.instance = new ClientConfig(config);
  }

  public getConfig() {
    return this.config;
  }
}
