'use client';

import { useZmyleCookieStore } from 'hooks/useZmyleCookieStore';
import { ClientConfig } from 'services/clientConfig';

import { GoogleAnalytics } from './GoogleAnalytics';
import { FacebookPixel } from './FacebookPixel';
import { LinkedInInsight } from './LinkedInInsight';

export const MarketingScripts = () => {
  const zmyleCookie = useZmyleCookieStore(state => state.zmyleCookie);

  const { marketing } = zmyleCookie.consents;

  const clientConfig = ClientConfig.getInstance().getConfig();

  const isGoogleAnalyticsEnabled =
    Boolean(clientConfig.googleAnalyticsId) &&
    marketing.includes('google-analytics');

  const isFacebookPixelEnabled =
    Boolean(clientConfig.facebookPixelId) &&
    marketing.includes('facebook-pixel');

  const isLinkedinInsightEnabled =
    Boolean(clientConfig.linkedinPartnerId) &&
    marketing.includes('linkedin-insight-tag');

  return (
    <>
      <GoogleAnalytics isEnabled={isGoogleAnalyticsEnabled} />

      <FacebookPixel isEnabled={isFacebookPixelEnabled} />

      <LinkedInInsight isEnabled={isLinkedinInsightEnabled} />
    </>
  );
};
