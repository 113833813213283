import { Img } from '@chakra-ui/react';
import Script from 'next/script';
import { useEffect } from 'react';
import { ClientConfig } from 'services/clientConfig';
import { getMarketingScriptVersion } from './getMarketingScriptVersion';

interface Props {
  isEnabled: boolean;
}

export const LinkedInInsight = (props: Props) => {
  const { isEnabled } = props;

  const clientConfig = ClientConfig.getInstance().getConfig();

  useEffect(() => {
    if (isEnabled) return;

    const linkedinSnippet = document.getElementById('linkedin-insight-snippet');

    const linkedinNoScript = document.getElementById('linkedin-noscript');

    if (linkedinSnippet) linkedinSnippet.remove();

    if (linkedinNoScript) linkedinNoScript.remove();
  }, [isEnabled]);

  if (!isEnabled) return null;

  return (
    <>
      <Script
        id="linkedin-insight-snippet"
        key="linkedin-insight-snippet"
        defer
        strategy="lazyOnload"
        type="text/javascript"
        src={`/scripts/marketing/linkedin-insight.js?v=${getMarketingScriptVersion()}`}
      />

      <noscript id="linkedin-noscript">
        <Img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt="LinkedIn Insight"
          loading="lazy"
          src={`https://px.ads.linkedin.com/collect/?pid=${clientConfig.linkedinPartnerId}&fmt=gif`}
        />
      </noscript>
    </>
  );
};
