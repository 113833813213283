import { ReactNode, createContext, useContext, useEffect, useRef } from 'react';
import { createStore, useStore } from 'zustand';

import { getZmyleCookie } from 'utils/helpers';
import { CookieWidget } from 'services/cookieWidget';

interface ZmyleCookieStore {
  zmyleCookie: ZmyleCookie;
  refresh: () => void;
}

const initializeStore = (preloadedState: Partial<ZmyleCookieStore> = {}) => {
  return createStore<ZmyleCookieStore>(set => ({
    zmyleCookie: getZmyleCookie(),
    ...preloadedState,
    refresh: () => set(() => ({ zmyleCookie: getZmyleCookie() })),
  }));
};

const ZmyleCookieContext = createContext<ReturnType<
  typeof initializeStore
> | null>(null);

interface Props {
  children: ReactNode;
  zmyleCookie?: ZmyleCookie;
}

export const ZmyleCookieProvider = (props: Props) => {
  const { children, zmyleCookie } = props;

  const storeRef = useRef<ReturnType<typeof initializeStore> | null>(null);
  const setOnCloseInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (setOnCloseInterval.current) clearInterval(setOnCloseInterval.current);

    const setOnClose = () => {
      if (!CookieWidget.isAvailable()) return;

      const cookieWidget = CookieWidget.getInstance();

      cookieWidget.setOnClose(success => {
        if (success) storeRef.current?.getState().refresh();
      });

      if (setOnCloseInterval.current) clearInterval(setOnCloseInterval.current);
    };

    setOnCloseInterval.current = setInterval(setOnClose, 100);

    return () => {
      if (setOnCloseInterval.current) clearInterval(setOnCloseInterval.current);
    };
  }, []);

  if (!storeRef.current && zmyleCookie) {
    storeRef.current = initializeStore({ zmyleCookie });
  }

  return (
    <ZmyleCookieContext.Provider value={storeRef.current}>
      {children}
    </ZmyleCookieContext.Provider>
  );
};

export const useZmyleCookieStore = <T,>(
  selector: (state: ZmyleCookieStore) => T,
) => {
  const store = useContext(ZmyleCookieContext);

  if (!store) throw new Error('Store is missing the provider');

  return useStore(store, selector);
};
