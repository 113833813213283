import Script from 'next/script';
import { useEffect } from 'react';

import { ClientConfig } from 'services/clientConfig';
import { getMarketingScriptVersion } from './getMarketingScriptVersion';

interface Props {
  isEnabled: boolean;
}

export const GoogleAnalytics = (props: Props) => {
  const { isEnabled } = props;

  const clientConfig = ClientConfig.getInstance().getConfig();

  useEffect(() => {
    window[`ga-disable-${clientConfig.googleAnalyticsId}`] = !isEnabled;

    if (isEnabled) return;

    const analyticsScript = document.getElementById('google-analytics');
    const analyticsSnippet = document.getElementById(
      'google-analytics-snippet',
    );

    if (analyticsScript) analyticsScript.remove();

    if (analyticsSnippet) analyticsSnippet.remove();
  }, [isEnabled]);

  if (!isEnabled) return null;

  return (
    <>
      <Script
        id="google-analytics"
        key="google-analytics"
        defer
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${clientConfig.googleAnalyticsId}`}
      />

      <Script
        id="google-analytics-snippet"
        key="google-analytics-snippet"
        defer
        strategy="lazyOnload"
        type="text/javascript"
        src={`/scripts/marketing/google-analytics.js?v=${getMarketingScriptVersion()}`}
      />
    </>
  );
};
