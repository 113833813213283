import { ComponentStyleConfig } from '@chakra-ui/react';

export const Checkbox: ComponentStyleConfig = {
  variants: {
    network: () => ({
      control: {
        bg: 'transparent',
        _checked: {
          bg: 'gray.50',
          borderColor: 'gray.50',
          color: 'network.secondary',
          _hover: {
            bg: 'gray.50',
            borderColor: 'gray.50',
            color: 'network.secondary',
            filter: 'brightness(0.9)',
          },
        },
      },
    }),
    zmyle: () => ({
      control: {
        bg: 'white',
        borderColor: 'gray.100',
        borderWidth: '1px',
        _focus: { outline: 0, boxShadow: 'none' },
        _hover: {
          bg: 'white',
          borderColor: 'gray.50',
          color: 'zmyle.primary',
          filter: 'brightness(0.9)',
        },
        _checked: {
          bg: 'white',
          borderColor: 'gray.100',
          borderWidth: '1px',
          color: 'zmyle.primary',
          _hover: {
            bg: 'white',
            borderColor: 'gray.50',
            color: 'zmyle.primary',
            filter: 'brightness(0.9)',
          },
        },
      },
    }),
  },
};
