'use client';

import { PropsWithChildren, useEffect, useState } from 'react';

import Script from 'next/script';
import { ChakraProvider } from '@chakra-ui/react';
import { ErrorBoundary } from '@appsignal/react';

import { CacheProvider } from '@chakra-ui/next-js';
import { Layout } from 'components';
import { theme } from 'styles/theme';
import { AppSignalClient } from 'services/appSignal';

import { MarketingScripts } from 'components/MarketingScripts';
import { ZmyleCookieProvider } from 'hooks/useZmyleCookieStore';
import { ClientConfig, IClientConfig } from 'services/clientConfig';

export interface ZmyleCookieConfig {
  zmyleCookie: ZmyleCookie;
  scriptVersion: string;
  zmyleApi: string;
}

interface Props extends PropsWithChildren {
  seo: Record<keyof SEO, string>;
  zmyleCookieConfig: ZmyleCookieConfig;
  layoutContent: LayoutContent;
  clientConfig: IClientConfig;
}

export const Providers = (props: Props) => {
  const { seo, children, zmyleCookieConfig, layoutContent, clientConfig } =
    props;

  const [isConfigReady, setIsConfigReady] = useState(false);

  useEffect(() => {
    ClientConfig.set(clientConfig);

    AppSignalClient.init();

    setIsConfigReady(true);
  }, [clientConfig]);

  if (!isConfigReady) return null;

  const appSignal = AppSignalClient.getInstance().getClient();

  return (
    <ErrorBoundary instance={appSignal}>
      <CacheProvider>
        <ChakraProvider theme={theme}>
          <ZmyleCookieProvider zmyleCookie={zmyleCookieConfig.zmyleCookie}>
            <MarketingScripts />

            <Script
              id="zmyle-widget-script"
              key="zmyle-widget-script"
              type="module"
              defer
              strategy="lazyOnload"
              src={`${clientConfig.zmyleWidgetUrl}?v=${zmyleCookieConfig.scriptVersion}`}
              data-zmyle-middleware-root-url={zmyleCookieConfig.zmyleApi}
            />

            <Layout defaultSEO={seo} content={layoutContent}>
              {children}
            </Layout>
          </ZmyleCookieProvider>
        </ChakraProvider>
      </CacheProvider>
    </ErrorBoundary>
  );
};
