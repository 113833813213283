import { ClientConfig } from 'services/clientConfig';
import { isServer } from 'utils/helpers';

import { release } from 'ZmyleReleaseInfo.js';

export const getMarketingScriptVersion = () => {
  if (ClientConfig.getInstance().getConfig().isProduction && !isServer()) {
    return release;
  }

  return Date.now();
};
