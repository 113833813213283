import AppSignal from '@appsignal/javascript';
import { plugin as breadcrumbsNetworkPlugin } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as breadcrumbsConsolePlugin } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as windowEventsPlugin } from '@appsignal/plugin-window-events';
import { plugin as pathDecoratorPlugin } from '@appsignal/plugin-path-decorator';

import { release } from '../ZmyleReleaseInfo.js';
import { ClientConfig } from './clientConfig';

const renameErrorNames = ['error', 'typeerror'];

interface SendErrorParams {
  error: Error;
  payload: Record<string, any>;
  tags?: Record<string, any>;
}

export class AppSignalClient {
  private appSignal: AppSignal;

  // eslint-disable-next-line no-use-before-define
  private static instance: AppSignalClient;

  private constructor() {
    const clientConfig = ClientConfig.getInstance().getConfig();

    this.appSignal = new AppSignal({
      key: clientConfig.appSignalKey,
      revision: release ?? undefined,
    });

    if (typeof window === 'undefined') return;

    this.appSignal.use(breadcrumbsNetworkPlugin());
    this.appSignal.use(breadcrumbsConsolePlugin());
    this.appSignal.use(windowEventsPlugin());
    this.appSignal.use(pathDecoratorPlugin());

    const bindOnError = window.onerror;

    window.onerror = (message, source, lineno, colno, error) => {
      if (error && renameErrorNames.includes(error.name.toLowerCase())) {
        // eslint-disable-next-line no-param-reassign
        error.name = error.message;
      }

      if (bindOnError) bindOnError(message, source, lineno, colno, error);
    };
  }

  public static init() {
    AppSignalClient.instance = new AppSignalClient();
  }

  public static getInstance() {
    if (!AppSignalClient.instance) {
      AppSignalClient.instance = new AppSignalClient();
    }

    return AppSignalClient.instance;
  }

  public getClient() {
    return this.appSignal;
  }

  public sendError(params: SendErrorParams) {
    const { error, payload, tags = null } = params;

    const span = this.appSignal.createSpan();

    if (renameErrorNames.includes(error.name.toLowerCase())) {
      error.name = error.message;
    }

    span.setError(error);
    span.setParams(payload);

    if (tags) span.setTags(tags);

    this.appSignal.send(span);
  }
}
