import Script from 'next/script';
import { useEffect } from 'react';
import { getMarketingScriptVersion } from './getMarketingScriptVersion';

interface Props {
  isEnabled: boolean;
}

export const FacebookPixel = (props: Props) => {
  const { isEnabled } = props;

  useEffect(() => {
    if (isEnabled) return;

    const facebookPixelSnippet = document.getElementById(
      'facebook-pixel-snippet',
    );

    if (facebookPixelSnippet) facebookPixelSnippet.remove();
  }, [isEnabled]);

  if (!isEnabled) return null;

  return (
    <Script
      id="facebook-pixel-snippet"
      key="facebook-pixel-snippet"
      defer
      strategy="lazyOnload"
      type="text/javascript"
      src={`/scripts/marketing/facebook-pixel.js?v=${getMarketingScriptVersion()}`}
    />
  );
};
